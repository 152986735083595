<template>
  <footer class="checkout_footer">
    <div class="checkout_footer__top">
      <div class="checkout_footer__container container">
        <AppLogo
          color="black"
          class="checkout_footer__logo"
        />

        <AppContact class="checkout_footer__contact" />

        <AppPaymentIcons class="checkout_footer__payment-icons" />
      </div>
    </div>
    <div class="checkout_footer__bottom">
      <div class="checkout_footer__container container">
        <div class="checkout_footer__copyright">
          &copy; {{ $config.public.siteName || 'travelbags.nl' }} {{ year }}
        </div>
        <div class="checkout_footer__legal">
          <nav
            v-if="data?.footerBottom?.[0]"
          >
            <strong class="visually-hidden">
              {{ data.footerBottom[0].Title }}
            </strong>
            <ul class="checkout_footer__legal-list">
              <li
                v-for="(group, index) in data.footerBottom[0].Links"
                :key="index"
              >
                <component
                  :is="getElement(group.Link)"
                  v-if="group.Name"
                  :to="group.Link"
                >
                  {{ group.Name }}
                </component>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import layoutQuery from '~layers/app/graphql/layout.gql'
import { AppLink } from '#components'

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

const getElement = (to: any) => {
  return to ? AppLink : 'span'
}

const year = new Date().getFullYear()
</script>

<!-- <style src="~layers/app/components/Checkout/Footer.css" /> -->
<style>
@import url("~layers/app/components/Checkout/Footer.css");
</style>
