<template>
  <ol class="steps">
    <li
      v-for="step in steps"
      :key="step.step"
      class="steps__step"
      :class="{ 'steps__step-todo': currentStep < step.step, 'steps__step-current': currentStep === step.step, 'steps__step-done': currentStep > step.step }"
    >
      <div
        class="steps__step-marker"
        :class="{ disabled: currentStep < step.step, dot: currentStep === step.step }"
      />
      <AppLink
        class="steps__link"
        :to="step.url"
      >
        {{ $t(step.name) }}
      </AppLink>
    </li>
  </ol>
</template>

<script setup lang="ts">
const route = useRoute()

const steps = [
  {
    step: 0,
    name: 'Shopping cart',
    url: '/cart/',
  },
  {
    step: 1,
    name: 'My details',
    url: '/checkout/',
  },
  {
    step: 2,
    name: 'Shipping',
    url: '/checkout/shipment/',
  },
  {
    step: 3,
    name: 'Payment',
    url: '/checkout/payment/',
  },
]

const currentStep = computed(() => {
  switch (true) {
    case route.path.includes('payment'):
      return 3
    case route.path.includes('shipment'):
      return 2
    case route.path.includes('checkout'):
      return 1
  }

  return 1
})
</script>

<!-- <style src="~layers/app/components/Checkout/Steps.css" /> -->
<style>
@import url("~layers/app/components/Checkout/Steps.css");
</style>
