<template>
  <div class="checkout checkout-layout">
    <div class="checkout_header__top">
      <div class="container checkout_header__top-container">
        <AppUsps
          v-if="data?.headerUsps?.[0]?.Usp?.length"
          class="checkout_header__usps"
          :usps="data.headerUsps[0].Usp"
          horizontal
        />
        <ThirdpartyEtrustedEmbed
          v-if="config.trustedShopsWidget"
          class="checkout_header__etrusted"
          :widget-id="config.trustedShopsWidget"
        />
        <AppLink
          class="checkout_header__thuiswinkel-waarborg"
          to="https://www.thuiswinkel.org/leden/travelbags-nl/certificaat/"
          target="_blank"
        >
          <IconLabelsThuiswinkelWaarborg
            class="icon icon--thuiswinkel-waarborg"
            filled
          />
        </AppLink>
      </div>
    </div>

    <header
      ref="header"
      class="checkout_header"
    >
      <div
        ref="wrapper"
        class="checkout_header__wrapper"
      >
        <div class="container checkout_header__container">
          <AppLogo
            class="checkout_header__logo"
            color="black"
            data-testid="logo"
          />

          <CheckoutSteps class="checkout__steps" />
        </div>
      </div>
    </header>
  </div>
</template>

<script lang="ts" setup>
import layoutQuery from '~layers/app/graphql/layout.gql'

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })
</script>

<!-- <style src="~layers/app/components/Checkout/Header.css" /> -->
<style>
@import url("~layers/app/components/Checkout/Header.css");
</style>
